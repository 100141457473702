import React, {useCallback} from "../_snowpack/pkg/react.js";
import {useStore} from "../_snowpack/pkg/react-redux.js";
import {Canvas2D} from "./canvas2D.js";
export const Stave = ({width, height, center = 71}) => {
  const store = useStore();
  const render = useCallback((context) => {
    const {width: width2, height: height2} = context.canvas;
    context.fillStyle = "#000000";
    context.fillRect(0, 0, width2, height2);
    const centerX = width2 / 2;
    const centerY = height2 / 2;
    const h = height2 / 20;
    context.strokeStyle = "#FFFFFF";
    context.lineWidth = 1;
    for (let i = -2; i <= 2; i++) {
      drawStaveLine(context, centerY, h, 2 * i);
    }
    const notes = store.getState().midi.keys.reduce((p, c) => {
      if (c && c.state === "Down") {
        p.push(c.note);
      }
      return p;
    }, []);
    if (!notes) {
      return;
    }
    context.fillStyle = "#FFFFFF";
    drawNote(context, center, centerY, h, notes, centerX);
  }, [store]);
  return /* @__PURE__ */ React.createElement(Canvas2D, {
    canvas: {width, height},
    render
  });
};
function drawStaveLine(context, centerY, h, line, x1 = 0, x2 = context.canvas.width) {
  const y = centerY + h * line;
  context.beginPath();
  context.moveTo(x1, y);
  context.lineTo(x2, y);
  context.stroke();
}
function drawNote(context, centerNote, centerY, h, notes, x) {
  let minLine;
  let maxLine;
  const noteW = h * 1.2;
  const noteH = h * 0.8;
  let previousLine;
  let lineSum = 0;
  let twoSided = false;
  for (const note of notes) {
    const line = -Math.round((note - centerNote) * 7 / 12);
    lineSum += line;
    if (minLine === void 0 || line < minLine) {
      minLine = line;
    }
    if (maxLine === void 0 || line > maxLine) {
      maxLine = line;
    }
    const y = centerY + h * line;
    context.beginPath();
    if (previousLine && Math.abs(line - previousLine) < 2) {
      context.ellipse(x + h, y, noteW, noteH, -Math.PI / 8, 0, 2 * Math.PI);
      twoSided = true;
    } else {
      context.ellipse(x - h, y, noteW, noteH, -Math.PI / 8, 0, 2 * Math.PI);
    }
    context.fill();
    previousLine = line;
  }
  if (minLine === void 0 || maxLine === void 0) {
    return;
  }
  const lineMinY = centerY + h * minLine - (lineSum > 0 ? 6 * h : 0);
  const lineMaxY = centerY + h * maxLine + (lineSum > 0 ? 0 : 6 * h);
  const lineX = x + (twoSided ? 0 : lineSum > 0 ? 0 : -2 * h);
  context.lineWidth = 3;
  context.strokeStyle = "#FFFFFF";
  context.beginPath();
  context.moveTo(lineX, lineMaxY);
  context.lineTo(lineX, lineMinY);
  context.stroke();
  context.lineWidth = 1;
  const x1 = x - 2 * h;
  const x2 = x + 2 * h;
  for (let i = minLine - minLine % 2; i < -4; i += 2) {
    drawStaveLine(context, centerY, h, Math.ceil(i), x1, x2);
  }
  for (let i = maxLine - maxLine % 2; i > 4; i -= 2) {
    drawStaveLine(context, centerY, h, Math.floor(i), x1, x2);
  }
}
