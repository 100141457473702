import React from "../_snowpack/pkg/react.js";
import {useSelector} from "./state/store.js";
export const NoteInfo = () => {
  const note = useSelector((s) => s.midi.notes[0]);
  if (!note) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, noteToLetter(note.note));
};
function noteToLetter(note) {
  switch (note % 12) {
    case 0:
      return "C";
    case 1:
      return "C#";
    case 2:
      return "D";
    case 3:
      return "D#";
    case 4:
      return "E";
    case 5:
      return "F";
    case 6:
      return "F#";
    case 7:
      return "G";
    case 8:
      return "G#";
    case 9:
      return "A";
    case 10:
      return "A#";
    case 11:
      return "B";
    default:
      return "Broken";
  }
}
