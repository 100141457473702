export function parseMidiMessage(bytes, timestamp = Date.now()) {
  const byte0 = bytes[0];
  if (byte0 === void 0) {
    return;
  }
  const byte1 = bytes[1];
  const byte2 = bytes[2];
  const messageType = byte0 >> 4;
  const channel = byte0 & 15;
  switch (messageType) {
    case 11:
      return {type: "ControlChange", timestamp, channel, control: byte1, value: bytes[2]};
    case 9:
      if (byte1 === void 0 || byte2 === void 0) {
        return;
      }
      return {type: "KeyDown", timestamp, channel, note: byte1, velocity: byte2};
    case 8:
      if (byte1 === void 0 || byte2 === void 0) {
        return;
      }
      return {type: "KeyUp", timestamp, channel, note: byte1, velocity: byte2};
    default:
      return void 0;
  }
}
