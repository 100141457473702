import Box from "../../_snowpack/pkg/@mui/material/Box.js";
import Container from "../../_snowpack/pkg/@mui/material/Container/Container.js";
import React from "../../_snowpack/pkg/react.js";
import {useAsync} from "../../_snowpack/pkg/react-async-hook.js";
import {usePseudoRandomColor} from "../crypto/random.js";
const textEncoder = new TextEncoder();
export function ActivationCode() {
  const randomColor = usePseudoRandomColor();
  const timeCounter = calculateTimeCounter();
  const signature = useAsync(async () => {
    const key = await crypto.subtle.importKey("raw", secret, {name: "HMAC", hash: "SHA-256"}, false, ["sign"]);
    const signature2 = await crypto.subtle.sign("HMAC", key, textEncoder.encode(timeCounter.toString()));
    return encode(signature2);
  }, []);
  if (!signature.result) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, signature.status);
  }
  return /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "sm"
  }, /* @__PURE__ */ React.createElement(Box, {
    component: "form",
    sx: {
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
      gap: 1,
      width: "100px"
    }
  }, signature.result.map((value, i) => /* @__PURE__ */ React.createElement("input", {
    key: i,
    readOnly: true,
    value,
    style: {
      textAlign: "center",
      width: "2ch",
      height: "2ch",
      verticalAlign: "middle",
      fontSize: 30,
      padding: 0,
      borderRadius: "1ch",
      border: "0.15ch solid",
      borderColor: value === "" ? void 0 : randomColor(value.charCodeAt(0))
    }
  }))));
}
function encode(signature, base = 36) {
  const bytes = new Uint8Array(signature);
  const values = [];
  let i = 0;
  let carry = 0;
  while (values.length < 25) {
    const byte = bytes[i];
    i++;
    if (byte === void 0) {
      values.push("");
    } else {
      carry += byte;
      while (carry > 0 && values.length < 25) {
        const value = carry % base;
        carry = Math.floor(carry / base);
        if (value < 10) {
          values.push(`${value}`);
        } else {
          values.push(String.fromCharCode(87 + value));
        }
      }
    }
  }
  return values;
}
const timeCounterWindow = 5e3;
const calculateTimeCounter = () => Math.round(Date.now() / timeCounterWindow);
const secret = new Uint8Array([
  112,
  251,
  0,
  34,
  100,
  235,
  91,
  168,
  81,
  234,
  124,
  242,
  92,
  238,
  23,
  4,
  5,
  86,
  252,
  198,
  55,
  103,
  39,
  57,
  43,
  154,
  65,
  160,
  134,
  50,
  215,
  231,
  164,
  42,
  181,
  205,
  246,
  139,
  137,
  29,
  141,
  246,
  153,
  39,
  229,
  122,
  19,
  76,
  34,
  24,
  87,
  86,
  85,
  173,
  53,
  141,
  87,
  110,
  144,
  73,
  174,
  45,
  223,
  197
]);
