import React, {useCallback} from "../_snowpack/pkg/react.js";
import {useStore} from "../_snowpack/pkg/react-redux.js";
import {Canvas2D} from "./canvas2D.js";
export const NotesView = ({width, height}) => {
  const store = useStore();
  const renderNote = (context, note, velocity, startAge, stopAge) => {
    const {width: width2, height: height2} = context.canvas;
    context.fillStyle = `hsl(${240 * (1 - velocity / 127)},50%,50%)`;
    const x1 = 1 - startAge / 5e3;
    const x2 = stopAge ? 1 - stopAge / 5e3 : 1;
    const h = height2 / 128;
    context.fillRect(width2 * x1, h * note, width2 * (x2 - x1), h);
  };
  const render = useCallback((context) => {
    const {notes, keys} = store.getState().midi;
    const now = Date.now();
    const {width: width2, height: height2} = context.canvas;
    context.fillStyle = "#000000";
    context.fillRect(0, 0, width2, height2);
    for (const {note, timestampDown, timestampUp, velocityDown} of notes) {
      renderNote(context, note, velocityDown, now - timestampDown, now - timestampUp);
    }
    for (const key of keys) {
      if (!key || key.state === "Up") {
        continue;
      }
      const {note, velocity, timestamp} = key;
      renderNote(context, note, velocity, now - timestamp);
    }
  }, [store]);
  return /* @__PURE__ */ React.createElement(Canvas2D, {
    canvas: {width, height},
    render
  });
};
