import React, {useEffect, useRef} from "../_snowpack/pkg/react.js";
export const Canvas2D = ({render, canvas}) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    let visible = true;
    let context = null;
    function renderLoop() {
      if (!visible) {
        return;
      }
      requestAnimationFrame(() => renderLoop());
      if (!canvasRef.current) {
        return;
      }
      if (!context) {
        context = canvasRef.current.getContext("2d");
      }
      if (context) {
        render(context);
      }
    }
    renderLoop();
    return () => {
      visible = false;
    };
  });
  return /* @__PURE__ */ React.createElement("canvas", {
    ...canvas,
    ref: canvasRef
  });
};
