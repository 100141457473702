import {combineReducers, configureStore, createReducer} from "../../_snowpack/pkg/@reduxjs/toolkit.js";
import {
  useSelector as _useSelector
} from "../../_snowpack/pkg/react-redux.js";
import {midiSlice} from "../midi/reducer.js";
const actionCount = createReducer(0, (b) => b.addDefaultCase((s, _a) => s + 1));
const reducer = combineReducers({
  actionCount,
  midi: midiSlice.reducer
});
export const store = configureStore({
  reducer
});
export const useSelector = _useSelector;
