import Box from "../../_snowpack/pkg/@mui/material/Box.js";
import Container from "../../_snowpack/pkg/@mui/material/Container/Container.js";
import React, {useRef, useState} from "../../_snowpack/pkg/react.js";
import {usePseudoRandomColor} from "../crypto/random.js";
export function Authenticate() {
  const randomColor = usePseudoRandomColor();
  const refs = emptyValues.map(() => useRef(null));
  const focus = useRef(0);
  const [values, setValues] = useState(emptyValues);
  const setValue = (i, value) => {
    setValues((values2) => {
      values2[i] = value.slice(-1);
      return [...values2];
    });
    const currrent = refs[i]?.current;
    if (currrent?.value === "") {
      return;
    }
    const next = refs[i + 1]?.current;
    if (!next) {
      return;
    }
    next.focus();
  };
  const onKeyPress = (i, e) => {
    console.log(e);
    if (e.code !== "Backspace") {
      return;
    }
    const currrent = refs[i]?.current;
    if (currrent?.value !== "") {
      return;
    }
    const previous = refs[i - 1]?.current;
    if (!previous) {
      return;
    }
    previous.focus();
  };
  return /* @__PURE__ */ React.createElement(Container, {
    maxWidth: "sm"
  }, /* @__PURE__ */ React.createElement(Box, {
    component: "form",
    sx: {
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
      gap: 1,
      width: "100px"
    }
  }, values.map((value, i) => /* @__PURE__ */ React.createElement("input", {
    key: i,
    ref: refs[i],
    onFocus: () => {
      focus.current = i;
    },
    onKeyDown: (e) => onKeyPress(i, e),
    value,
    onChange: (e) => setValue(i, e.target.value),
    style: {
      textAlign: "center",
      width: "2ch",
      height: "2ch",
      verticalAlign: "middle",
      fontSize: 30,
      padding: 0,
      borderRadius: "1ch",
      border: "0.15ch solid",
      borderColor: value === "" ? void 0 : randomColor(value.charCodeAt(0))
    }
  }))));
}
const emptyValues = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  ""
];
