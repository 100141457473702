import React, {StrictMode} from "../_snowpack/pkg/react.js";
import {Provider} from "../_snowpack/pkg/react-redux.js";
import {MidiProvider} from "./midi/provider.js";
import {Pages} from "./pages.js";
import {store} from "./state/store.js";
export default function App() {
  return /* @__PURE__ */ React.createElement(StrictMode, null, /* @__PURE__ */ React.createElement(Provider, {
    store
  }, /* @__PURE__ */ React.createElement(MidiProvider, null, /* @__PURE__ */ React.createElement(Pages, null))));
}
