import React from "../_snowpack/pkg/react.js";
import {
  HashRouter,
  Route,
  Routes
} from "../_snowpack/pkg/react-router-dom.js";
import {MainPage} from "./mainPage.js";
import {ActivationCode} from "./pages/activationCode.js";
import {Authenticate} from "./pages/authentication.js";
export const Pages = () => /* @__PURE__ */ React.createElement(HashRouter, null, /* @__PURE__ */ React.createElement(Routes, null, /* @__PURE__ */ React.createElement(Route, {
  path: "/",
  element: /* @__PURE__ */ React.createElement(MainPage, null)
}), /* @__PURE__ */ React.createElement(Route, {
  path: "authenticate",
  element: /* @__PURE__ */ React.createElement(Authenticate, null)
}), /* @__PURE__ */ React.createElement(Route, {
  path: "activation",
  element: /* @__PURE__ */ React.createElement(ActivationCode, null)
})));
