import React, {createContext, useState} from "../../_snowpack/pkg/react.js";
export const midiAccessContext = createContext({error: true});
export const MidiProvider = ({children}) => {
  const [access, setAccess] = useState();
  const [error, setError] = useState(false);
  if (!access && !error) {
    try {
      navigator.requestMIDIAccess().then((a) => setAccess(a), () => setError(true));
    } catch {
      setError(true);
    }
  }
  return /* @__PURE__ */ React.createElement(midiAccessContext.Provider, {
    value: {access, error}
  }, children);
};
