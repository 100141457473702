import { r as react } from './common/index-8af8b000.js';
export { r as default } from './common/index-8af8b000.js';



var StrictMode = react.StrictMode;
var createContext = react.createContext;
var useCallback = react.useCallback;
var useContext = react.useContext;
var useEffect = react.useEffect;
var useReducer = react.useReducer;
var useRef = react.useRef;
var useState = react.useState;
export { StrictMode, createContext, useCallback, useContext, useEffect, useReducer, useRef, useState };
