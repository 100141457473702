export function usePseudoRandomColor() {
  let state0 = 1;
  let state1 = 2;
  return (s) => {
    if (s) {
      state0 ^= s;
      state1 ^= s;
    }
    state0 = 18030 * (state0 & 65535) + (state0 >> 16 & 65535);
    state1 = 30903 * (state1 & 65535) + (state1 >> 16 & 65535);
    const r = state0 & 1 ? 200 : 20;
    const g = state1 & 1 ? 200 : 20;
    const b = state0 & 16 ? 200 : 20;
    return `rgb(${r},${g},${b})`;
  };
}
