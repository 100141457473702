import {createSlice} from "../../_snowpack/pkg/@reduxjs/toolkit.js";
import {parseMidiMessage} from "./message.js";
export const midiSlice = createSlice({
  name: "midi",
  initialState: {
    inputs: {},
    outputs: {},
    messages: [],
    keys: new Array(127),
    notes: []
  },
  reducers: {
    addInput: (s, a) => {
      s.inputs[a.payload] = {};
    },
    removeInput: (s, a) => {
      delete s.inputs[a.payload];
    },
    addOutput: (s, a) => {
      s.outputs[a.payload] = {};
    },
    removeOutput: (s, a) => {
      delete s.outputs[a.payload];
    },
    midiMessage: (s, {payload: {data, timestamp}}) => {
      const message = parseMidiMessage(data, timestamp);
      if (!message) {
        return;
      }
      s.messages = s.messages.slice(0, 128);
      s.messages.unshift(message);
      switch (message.type) {
        case "KeyDown": {
          const {note, timestamp: timestamp2, velocity} = message;
          s.keys[note] = {state: "Down", note, velocity, timestamp: timestamp2};
          break;
        }
        case "KeyUp": {
          const {note, timestamp: timestamp2, velocity} = message;
          const down = s.keys[message.note];
          s.keys[note] = {state: "Up", note, velocity, timestamp: timestamp2};
          if (!down) {
            break;
          }
          s.notes = s.notes.slice(0, 128);
          s.notes.unshift({
            note,
            velocityDown: down.velocity,
            velocityUp: velocity,
            timestampDown: down.timestamp,
            timestampUp: timestamp2
          });
          break;
        }
        default:
      }
    }
  }
});
