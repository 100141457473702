import React, {useContext, useEffect, useState} from "../_snowpack/pkg/react.js";
import {useDispatch} from "../_snowpack/pkg/react-redux.js";
import {InputPort} from "./inputPort.js";
import {midiAccessContext} from "./midi/provider.js";
import {midiSlice} from "./midi/reducer.js";
import {NoteInfo} from "./noteInfo.js";
import {NotesView} from "./notesView.js";
import {Stave} from "./stave.js";
export const MainPage = () => {
  const dispatch = useDispatch();
  const [deviceId, setDeviceId] = useState("");
  const {access} = useContext(midiAccessContext);
  const input = access?.inputs.get(deviceId);
  useEffect(() => {
    const onStateChange = (e) => setDeviceId(e.port.id);
    const onMidiMessage = (e) => {
      const data = Array.from(e.data);
      const timestamp = Date.now();
      dispatch(midiSlice.actions.midiMessage({data, timestamp}));
    };
    access?.addEventListener("statechange", onStateChange);
    input?.addEventListener("midimessage", onMidiMessage);
    return () => {
      input?.removeEventListener("midimessage", onMidiMessage);
      access?.removeEventListener("statechange", onStateChange);
    };
  });
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h1", null, "midi.network"), access && /* @__PURE__ */ React.createElement("select", {
    value: deviceId,
    onChange: (e) => setDeviceId(e.target.value)
  }, /* @__PURE__ */ React.createElement("option", {
    value: ""
  }, "Please select an input device"), [...access.inputs].map(([id, {name, manufacturer}]) => /* @__PURE__ */ React.createElement("option", {
    key: id,
    value: id
  }, name, " ", manufacturer))), deviceId && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(NoteInfo, null), /* @__PURE__ */ React.createElement(InputPort, {
    id: deviceId
  }), /* @__PURE__ */ React.createElement(Stave, {
    width: Math.floor(window.innerWidth - 50),
    height: 250
  }), /* @__PURE__ */ React.createElement(NotesView, {
    width: Math.floor(window.innerWidth - 50),
    height: 250
  })));
};
