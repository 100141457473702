import React, {useContext, useState} from "../_snowpack/pkg/react.js";
import {midiAccessContext} from "./midi/provider.js";
export const ConnectionStatus = ({connection}) => {
  if (connection === "open") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, "🟢");
  }
  if (connection === "pending") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, "🟡");
  }
  if (connection === "closed") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, "⭕");
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, "🔴");
};
export const InputPort = ({id}) => {
  const {access} = useContext(midiAccessContext);
  const input = access?.inputs.get(id);
  const [deviceString] = useState(`${input?.name} ${input?.manufacturer}`);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h1", null, /* @__PURE__ */ React.createElement(ConnectionStatus, {
    connection: input?.connection
  }), " ", deviceString));
};
